<div *ngIf="showForm" class="card">
    <hr />
    <h4 class="d-flex p-2 bd-highlight">Existing DJL/MPT</h4>
    <form class="form-container" [formGroup]="myDfnForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
            <div class="form-group col-md-6" *ngIf="showDFLFieldsDFN">
                <label for="dfn_upstream_equipment_id" class="lable-css">DJL/MPT ID:</label>
                <input type="text" id="dfn_upstream_equipment_id" formControlName="dfn_upstream_equipment_id"
                    placeholder="DJL/MPT ID">
            </div>
            <!-- <div class="form-group col-md-6">
                <label for="equipment" class="lable-css">Equipment:</label>
                <select id="equipment" formControlName="equipment" class="form-select">
                    <option value="" selected>Select</option>
                    <option value="PCD">PCD</option>
                    <option value="BUDI">BUDI</option>
                    <option value="CTL">CTL</option>
                </select>
            </div> -->
            <div>

                <h4 class="d-flex p-2 bd-highlight">New FJL</h4>
                <div class="form-group row">

                    <div class="form-group col-md-6">
                        <label for="fjl_id" class="lable-css">New FJL ID:</label>
                        <input type="text" id="fjl_id" formControlName="fjl_id">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="fjl_structure_id" class="lable-css">New FJL pit ID:</label>
                        <input type="text" id="fjl_structure_id" formControlName="fjl_structure_id">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-group  col-md-6">
                        <label for="fsd_cable" class="lable-css">New FSD cable ID:</label>
                        <input type="text" id="fsd_cable" formControlName="fsd_cable">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="fsd_cable_spec" class="lable-css">Cable Specification:</label>
                        <select id="fsd_cable_spec" formControlName="fsd_cable_spec" class="form-select">
                            <option value="FSD_CORNING_12_RIBBON">FSD_CORNING_12_RIBBON</option>
                            <option value="FSD_CORNING_1_RIBBON">FSD_CORNING_1_RIBBON</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="showDFLFieldsDFN">
            <div class="form-group  col-md-6">
                <label for="numberOfDjl" class="lable-css">Number of New DJL:</label>
                <select id="numberOfDjl" formControlName="numberOfDjl" (change)="onNumberOfDjlChange()"
                    class="form-select">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <!-- Add more options as needed -->
                </select>
            </div>
        </div>
        <div class="form-group row" formArrayName="formGroupsArray">
            <div class="loop-border" *ngFor="let group of formGroupsArray.controls; let i = index" [formGroupName]="i">
                <!-- Use your form controls here -->

                <h3>New DJL {{i +1}}</h3>
                <div class="form-group row">
                    <div class="form-group col-md-6">
                        <label for="djl_id">New DJL ID:</label>
                        <input type="text" id="djl_id{{i}}" formControlName="djl_id">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="djl_structure_id">New DJL pit ID:</label>
                        <input type="text" id="djl_structure_id{{i}}" formControlName="djl_structure_id">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-group col-md-6">
                        <label for="dss_cable">New DSS cable ID:</label>
                        <input type="text" id="dss_cable{{i}}" formControlName="dss_cable">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="dss_cable_spec" class="lable-css">Cable Specification:</label>
                        <select class="form-select" id="dss_cable_spec{{i}}" formControlName="dss_cable_spec">
                            <option value="DSS_CORNING_12_RIBBON">DSS_CORNING_12_RIBBON</option>
                            <option value="DSS_CORNING_72_RIBBON">DSS_CORNING_72_RIBBON</option>
                            <option value="DSS_CORNING_144_RIBBON">DSS_CORNING_144_RIBBON</option>
                            <option value="DSS_CORNING_288_RIBBON">DSS_CORNING_288_RIBBON</option>
                            <option value="DSS_CORNING_576_RIBBON">DSS_CORNING_576_RIBBON</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="center-first-row">
            <button type="submit" [class]="myDfnForm.invalid ? 'gray-button' : ''"
                [disabled]="myDfnForm.invalid">Submit</button>
        </div>


    </form>
</div>

<div *ngIf="upstream_screen">
    <app-sd1-upstream-equipment
        [inputData]="inputData"
    ></app-sd1-upstream-equipment>
</div>

<div *ngIf="smp_connectivity_flag">
  <app-smp-connectivity-sd1
    [inputData] = "inputData"
  ></app-smp-connectivity-sd1>
</div>

<div *ngIf="isLoading">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>

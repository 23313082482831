import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TableComponent } from "../../common/table/table.component";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../api.service";
import { CommonModule } from "@angular/common";
import { JlStructureComponent } from "../../common/jl-structure/jl-structure.component";
import { TitleTextPipe } from "../../../custom.pipe";
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormGroup, FormBuilder } from "@angular/forms";
import { UploadFileComponent } from "../../common/upload-file/upload-file.component";
import { MessageComponent } from "../../common/message/message.component";

@Component({
  selector: "app-dfn-path-generartion",
  standalone: true,
  imports: [
    TableComponent,
    CommonModule,
    NgxSpinnerModule,
    JlStructureComponent,
    TitleTextPipe,UploadFileComponent,
    ReactiveFormsModule,MessageComponent
  ],
  templateUrl: "./dfn-path-generartion.component.html",
  styleUrls: ["./dfn-path-generartion.component.scss"]
})

export class DfnPathGenerartionComponent implements OnInit {
  @Input() inputData: any;
  @Input() table_title: string = '';
  tableData: any;
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  selection= new SelectionModel<any>(true, []);
  selectedRow: any;
  showTable: boolean = true;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  showJlStructure: boolean = false;
  showADRCard: boolean = false;
  alternate_path: boolean = false;
  file: string | null = null;
  url: string = '';
  filename: string = '';
  msg: string = ''; 
  myAdrForm!: FormGroup;
  flowName = "MDU"

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 
    this.myAdrForm = this.fb.group({
      ADRpath: [''],
    });
  }

  ngOnInit() {

  }

  goBackCall(data:any) {
    
  }
  fileUploaded(event: string) {
    this.file = event;
  }

  receiveTableEvent(data: any) {
    this.inputData['selected_dfn_path'] = data;
    this.isLoading = true;
    this.spinner.show()  
    this.apiService.onSelectDfnPath(this.inputData).subscribe({
      next: (response) => {
            if (response.message == 'select bjl structure dfn') {
              this.showTable = false;
              this.showJlStructure = true;
              this.inputData = response;
              this.tableData = response;
            }
            else if (response.message == 'receive adr') {
               this.router.navigateByUrl('/adr');
            }
            else if (response.message == 'upload adr') {
              this.showADRCard = true;
              this.showTable = false;
            }
            else {              
              if ('file_name' in response) {
                this.showTable = false;
                this.showMessage = true;
                this.MessageToShow = response;
              } else {
                this.toastr.error(response.message, 'Error Message');
                console.log('error');
              }
            }

            this.isLoading = false;
            this.spinner.hide();
            // window.alert(response.messgae);
      },
      error: (error) => {
            this.isLoading = false;
            this.spinner.hide();
            this.toastr.error(
              `The API returned Unsuccessfull response : ${error}`,
              'Error Message'
            );
            //window.alert(`The API returned Unsuccessfull response : ${error}`);
          },
    });
  }

  table_function_called() {
    this.isLoading = true;
    this.spinner.show();
    if (this.selectedRow && this.selectedRow.length > 0) {
      let new_route_list = [];
      for (let ele of this.selectedRow) {
        new_route_list.push(ele['Paths']);
      }
      this.inputData['new_route_list'] = new_route_list;
    }
    this.inputData['adr_path'] = this.myAdrForm.value.ADRpath;
    this.inputData['adr_file_name'] = this.file;

    this.inputData['available_paths'] = this.inputData['available_paths'];
    this.apiService.onadrcall(this.inputData).subscribe({
      next: (response) => {
        this.showADRCard = false;

        if (
          response.message.indexOf(
            "Tool generated path doesn't match with ADR"
          ) != -1
        ) {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        } else if (response.message == 'Pre-signed url has been generated') {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        else if(response.message == 'alternate path'){
            this.showADRCard = false;
            this.alternate_path = true;    
            this.inputData =  response;  
            //this.tableChildComponent.initializeFunction(this.inputData);
        } 
        else {
          this.url = response.url;
          this.filename = response.file_name;
          this.msg = response.message;
          this.showMessage = true;
          this.MessageToShow = JSON.stringify(response);
        }
        this.isLoading = false;
        this.spinner.hide();
        // window.alert(response.messgae);
      },
      error: (error) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error(
          `The API returned Unsuccessfull response : ${error}`,
          'Error Message'
        );
        //window.alert(`The API returned Unsuccessfull response : ${error}`);
      },
    });
  }
  
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  private baseUrl_SD1 = environment.apiUrl+'/sd1';

  private dataSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  // Observable to which components can subscribe
  data$ = this.dataSubject.asObservable();

  shareData(data: any) {
    this.dataSubject.next(data);
  }
  //TODO: rename createmap to initiatemap 
  initiatemapinfodesign(queryString: string): Observable<any> {
    const url = `${this.baseUrl}/initiatemapinfodesign` + queryString;
    console.log(url)
    return this.http.post(url, {});
  } 
  
  selectEndEquipment(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectendequipment`
    return this.http.post(url, dataToSend, { headers })
  }

  bjlpathoverwrite(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/bjlpathoverwrite`
    return this.http.post(url, dataToSend, { headers })
  }
  
  selectjlStructure(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectjlstructure`
    return this.http.post(url, dataToSend, { headers })
  }
  //TODO : add jl_type field 
  selectUpstremEquipment(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectupstreamequipment`
    return this.http.post(url, dataToSend, { headers })
  }
  //TODO : remove api and add into  selectupstreamequipment with jl_type 
  selectAjlUpstremEquipment(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectupstreamequipment`
    return this.http.post(url, dataToSend, { headers })
  }
  selectStructure(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectstructure`
    return this.http.post(url, dataToSend, { headers })
  }
  selectEquip(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectequipment`
    return this.http.post(url, dataToSend, { headers })
  }
  uploadmduosp(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/uploadmduosp`
    return this.http.post(url, dataToSend, { headers })
  }
  onSelectPath(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectpath`
    return this.http.post(url, dataToSend, { headers })
  }
  onadrcall(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/uploadadr`
    return this.http.post(url, dataToSend, { headers })
  }
  
  onAlternatePath(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/alternatepath`
    return this.http.post(url, dataToSend, { headers })
  }

  onInitiateDfn(dataToSend: any, queryString: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/initiatedfnmapinfodesign` + queryString
    return this.http.post(url, dataToSend, { headers })
  }

  onSelectDfnPath(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectdfnpath`
    return this.http.post(url, dataToSend, { headers })
  }

  // remove api , and add in selectjlstructure
  onSelectDfnBjlStr(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectjlstructure`
    return this.http.post(url, dataToSend, { headers })
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

  uploadFile(dataToSend: any): Observable<any> {
    //const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    const url = `${this.baseUrl}/uploadfile`
    return this.http.post(url, dataToSend)
  }

  ///// ADR APIs ////
  initiatemapinfodesignadr(queryString: string): Observable<any> {
    const url = `${this.baseUrl}/initiatemapinfodesignadr` + queryString;
    console.log(url)
    return this.http.post(url, {});
  }

  onInitiateDfnAdr(dataToSend: any, queryString: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/initiatedfnmapinfodesignadr` + queryString
    return this.http.post(url, dataToSend, { headers })
  }

  uploadmduospAdr(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/uploadmduospadr`
    return this.http.post(url, dataToSend, { headers })
  }

  selectEquipAdr(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectequipmentadr`
    return this.http.post(url, dataToSend, { headers })
  }

  selectStructureAdr(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectstructureadr`
    return this.http.post(url, dataToSend, { headers })
  }

  receiveAdrcall(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/receiveadr`
    return this.http.post(url, dataToSend, { headers })
  }

  selectUpstremEquipmentAdr(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectupstreamequipmentadr`
    return this.http.post(url, dataToSend, { headers })
  }

  selectBjl(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl}/selectbjl`
    return this.http.post(url, dataToSend, { headers })
  }


  /* SD1 APIs */
  //TODO: rename createmap to initiatemap 
  initiatemapinfodesignSD1(queryString: string): Observable<any> {
    const url = `${this.baseUrl_SD1}/initiatemapinfodesign` + queryString;
    console.log(url)
    return this.http.post(url, {});
  } 
  selectBjlSD1(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/selectbjl`
    return this.http.post(url, dataToSend, { headers })
  }

  selectSmpLocationSD1(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/selectsmplocations`
    return this.http.post(url, dataToSend, { headers })
  }

  selectSmpStructure(dataToSend: any,msg:string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = '';
    if (msg == 'select smp structure') {
      url = `${this.baseUrl_SD1}/selectsmpstructure`;
    }
    else {
      url = `${this.baseUrl_SD1}/selectbjlstructure`;
    }
    return this.http.post(url, dataToSend, { headers })
  }
  
  selectBJLStructure(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/selectbjlstructure`
    return this.http.post(url, dataToSend, { headers })
  }

  selectUpstreamStructure(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/selectupstreamequipment`
    return this.http.post(url, dataToSend, { headers })
  }

  generateSd1Output(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/generatesd1output`
    return this.http.post(url, dataToSend, { headers })
  }

  bjlpathoverwriteSD1(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/bjlpathoverwrite`
    return this.http.post(url, dataToSend, { headers })
  }
  onadrcallSD1(dataToSend: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/uploadadr`
    return this.http.post(url, dataToSend, { headers })
  }
  onInitiateDfnSD1(dataToSend: any, queryString: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.baseUrl_SD1}/initiatemapinfodesign` + queryString
    return this.http.post(url, dataToSend, { headers })
  }
 
}

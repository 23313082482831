import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SmpConnectivitySd1Component } from '../smp-connectivity-sd1/smp-connectivity-sd1.component';
import { Sd1UpstreamEquipmentComponent } from '../sd1-upstream-equipment/sd1-upstream-equipment.component';

@Component({
  selector: 'app-dfn-djl-flow',
  standalone: true,
  imports: [
    NgxSpinnerModule,
    ReactiveFormsModule,
    CommonModule,
    SmpConnectivitySd1Component,Sd1UpstreamEquipmentComponent
  ],
  templateUrl: './dfn-djl-flow.component.html',
  styleUrl: './dfn-djl-flow.component.scss'
})
export class DfnDjlFlowComponent {
  @Input() inputData: any;
  @Input() showDFLFieldsDFN: boolean = false;
  @Input() file: string | null = null; 
  @Output() disableMainForm = new EventEmitter<string>();
  myDfnForm!: FormGroup;
  showForm: boolean = true;
  query: any;
  showMap: boolean = false;
  mapData: any;
  isLoading: boolean = false;
  showMessage: boolean = false;
  MessageToShow: any;
  upstream_screen = false;
  smp_connectivity_flag = false;
  
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {

    this.myDfnForm = this.fb.group({
      numberOfDjl: [],
      //equipment: ['', Validators.required],
      fjl_id: ['', Validators.required],
      dfn_upstream_equipment_id: [],
      fjl_structure_id: ['', Validators.required],
      fsd_cable: ['', Validators.required],
      fsd_cable_spec: ['', Validators.required],
      formGroupsArray: this.fb.array([]),
    });
  }
  ngOnInit(): void {

  }

  onMduCall() {

  }
  get formGroupsArray(): FormArray {
    return this.myDfnForm?.get('formGroupsArray') as FormArray;
  }

  createFormGroup(index: number): FormGroup {
    return this.fb.group({
      // Define your form controls here
      // For example:
      djl_id: ['', Validators.required],
      djl_structure_id: ['', Validators.required],
      dss_cable: ['', Validators.required],
      dss_cable_spec: ['', Validators.required],
    });
  }

  onSubmit() {
    this.isLoading = true
    this.spinner.show();
    let body: any = {}
    //this.query = `?tech_type=${this.inputData.value.techType}&built_type=${this.inputData.value.builtType}&ufa_category=${this.inputData.value.ufaCategory}&architecture_type=${this.inputData.value.architectureType}&s18_path=${this.inputData.value.S18path}&ror_id=${this.inputData.value.rorId}&loc_id=${this.inputData.value.locId}&ada_id=${this.inputData.value.adaId}&fjl_structure_id=${this.inputData.value.fjlStru}`
    //this.query = `?tech_type=${this.inputData.value.techType}&ufa_category=${this.inputData.value.ufaCategory}&architecture_type=${this.inputData.value.architectureType}&ror_id=${this.inputData.value.rorId}&loc_id=${this.inputData.value.locId}&ada_id=${this.inputData.value.adaId}&fjl_structure_id=${this.inputData.value.fjlStru}`
    let architectureType = ((this.inputData.value.architectureType != undefined) ? this.inputData.value.architectureType : 'Type3');
    this.query = `?ufa_category=${this.inputData.value.ufaCategory}&architecture_type=${this.inputData.value.architectureType}&ror_id=${this.inputData.value.rorId}&loc_id=${this.inputData.value.locId}&ada_id=${this.inputData.value.adaId}&mps_boundary=${this.inputData.value.mps_boundary}&loc_id_file=${this.file}&dwelling_type=${this.inputData.value.dwelling_type}`;      
    //this.query = `?ufa_category=${this.inputData.value.ufaCategory}&architecture_type=${this.inputData.value.architectureType}&ror_id=${this.inputData.value.rorId}&loc_id=${this.inputData.value.locId}&ada_id=${this.inputData.value.adaId}&mps_boundary=${this.inputData.value.mps_boundary}&loc_id_file=${this.file}&dwelling_type=${this.inputData.value.dwelling_type}&s18_path=C:\\Users\\deepika.goswami\\Downloads\\BRQ000000026773\\BRQ000000026773`
    //this.query = `?tech_type=${this.inputData.value.techType}&ufa_category=${this.inputData.value.ufaCategory}&architecture_type=${architectureType}&ror_id=${this.inputData.value.rorId}&loc_id=${this.inputData.value.locId}&ada_id=${this.inputData.value.adaId}&sjl_pit=${this.inputData.value.sjl_pit}&fan_pit=${this.inputData.value.fan_pit}&dfl=${this.inputData.value.dfl}&btd_type=${this.inputData.value.btd_type}`
    body["djl_list"] = this.myDfnForm.value.formGroupsArray

    body["fjl_list"] = [{}]
    body["fjl_list"][0]["fjl_id"] = this.myDfnForm.value.fjl_id
    body["fjl_list"][0]["fjl_structure_id"] = this.myDfnForm.value.fjl_structure_id
    body["fjl_list"][0]["fsd_cable"] = this.myDfnForm.value.fsd_cable
    body["fjl_list"][0]["fsd_cable_spec"] = this.myDfnForm.value.fsd_cable_spec
    body["dfn_upstream_equipment_id"] = this.myDfnForm.value.dfn_upstream_equipment_id
    //body["equipment"] = this.myDfnForm.value.equipment
    console.log(this.query)
    console.log(body)
    this.isLoading = true;
    this.spinner.show();

    this.apiService.onInitiateDfnSD1(body, this.query).subscribe(
      {
        next: response => {
          this.isLoading = false;
          this.showForm = false;
          if (response.message === 'select upstream equipment sd1') {
            this.showForm = false;
            this.upstream_screen = true;
            this.inputData = response;
            this.disableMainForm.emit();
          } else if (response.message === 'select existing smp') {
              this.smp_connectivity_flag = true;
              this.inputData = response;
              this.disableMainForm.emit();
          } else {
            
            if ('file_name' in response) {
              this.showMessage = true;
              this.MessageToShow = response;
              this.disableMainForm.emit();
            } else {
              this.toastr.error(response.message, 'Error Message');
              console.log('error');
              this.showForm = true;
            }
          }

          this.isLoading = false;
          this.spinner.hide();
          // Handle the response data
        },
        error: error => {

          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(`The API returned Unsuccessfull response : ${error}`, 'Error Message');

        },
      }
    );

  }

  onNumberOfDjlChange(): void {
    const numberOfDjlControl = this.myDfnForm?.get('numberOfDjl')?.value;

    // Clear existing form groups
    while (this.formGroupsArray.length !== 0) {
      this.formGroupsArray.removeAt(0);
    }

    // Add new form groups
    for (let i = 0; i < numberOfDjlControl; i++) {
      this.formGroupsArray.push(this.createFormGroup(i));
    }
  }
}
